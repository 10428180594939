import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import AtsHighlighter from '../../common/AtsHighlighter';
import QuickSearchItem from '../../common/QuickSearchItem';

import {isEmpty, compact, get, keyBy, pick, uniqBy, has} from '../../../libs/lodash';
import searchConst from '../../../constants/searchConst';
import utils from '../../../libs/utils';

import apiUserStatus from '../../../services/apiUserStatus';

import './style.scss';
import ApiClient from '../../../services/ApiClient';

export default function AtsTypeAheadSearchSelect(props){
  const typeaheadRef = {};
  const filterBy = searchConst.entityTypeAheadFilterBy;

  const [entityType, setEntityType] = useState(props.entityType);
  const [entitySelected, setEntitySelected] = useState([]);
  const [entityOptions, setEntityOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isCity = (props.name || '').toLowerCase().includes('city');
  const isState = (props.name || '').toLowerCase().includes('state');
  const [isMulti, setIsMulti] = useState(false);
  const excludeOptions = props.excludeOptions || [];
  const excludeEntityTypes = props.excludeEntityTypes || [];
  const defaultRecentViewedOptions = props.showRecentViewed && (get(apiUserStatus.get() || {}, 'recentViewedEntities.' + props.entityType) || []);

  useEffect(() => {
    setEntityType(props.entityType);
    const defaultOptions = props.showRecentViewed && (get(apiUserStatus.get() || {}, 'recentViewedEntities.' + props.entityType) || []);
    if(props.isMulti) {
      setEntitySelected(props.defaultValue || []);
      setEntityOptions(props.defaultValue || defaultOptions);
      setIsMulti(true);
    } else {
      setEntitySelected(props.defaultValue ? [props.defaultValue] : []);
      setEntityOptions(props.defaultValue? [props.defaultValue] : defaultOptions);
      setIsMulti(false);
    }
  }, [props.defaultValue, props.isMulti, props.showRecentViewed, props.entityType]);

  const typeaheadOnSearch = async (query) => {
    if (!query ||  query.length < searchConst.minLength) {
      return;
    }

    if(props.maxLengthToApi && query.length > props.maxLengthToApi) {
      return;
    }
    setIsLoading(true);
    try {
      const searchOperation = props.searchOperation || 'searchQuick';

      let params;
      switch (searchOperation) {
      case 'listsSearchMy':
        params = {entityType: entityType, offset: 0, maxResults: 2000};
        break;
      case 'searchesSavedListMy':
        params = {entityType: entityType, offset: 0, maxResults: 2000, includeSharedWithMe: true};
        break;
      case 'usersSearch':
      case 'usersList':
        params = { search: query, isApp: false };
        break;
      default:
        params = entityType ? {query, entityType} : {query};
      }

      if (props.country) {
        params = {...params, country: props.country};
      }

      const response = await ApiClient[searchOperation](params);

      switch(searchOperation) {
      case 'searchQuick':
        const items = (response.body || []).map((item) => {
          item.entityType = item.entityType ? item.entityType : entityType;
          item.entityId = item.entityId ? +item.entityId : +item[(item.entityType || '').toLowerCase() + 'Id'];
          item.searchId = '' + (item.entityId || '');
          item.searchStringId = '' + get(item, 'string' + utils.camelize(item.entityType) +'Id', '');

          (props.filterBy || filterBy).forEach(function (field) {
            item[field] = item[field] || '';
          });

          const {firstName, lastName} = item.candidateName || item.contactName || item;
          if (firstName || lastName) {
            item.fullName = compact([firstName, lastName]).join(' ');
          }

          return item;
        }).filter(item => !excludeEntityTypes.includes(item.entityType));

        setEntityOptions(items);
        break;
      case 'locationsSearch':
        if(isState) {
          setEntityOptions(uniqBy(response.body.map((item) => {
            return pick(item, ['state']);
          }), ['state']));
        } else {
          setEntityOptions(response.body.map((item) => {
            item.location = compact([item.city, item.state]).join(', ');
            return item;
          }));
        }
        break;
      case 'listsSearchMy':
        setEntityOptions(response.body.lists || []);
        break;
      case 'searchesSavedListMy':
        setEntityOptions(response.body.savedSearches || []);
        break;
      case 'usersSearch':
      case 'usersList':
        setEntityOptions(response.body.users.map((item) => {
          item.name = compact([item.firstName, item.lastName]).join(' ');
          return item;
        }));
        break;
      default:
        break;
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setEntityOptions([]);
      setIsLoading(false);
    }
  };

  const typeaheadOnBlur = (e) => {
    e.preventDefault();
    try {
      if(props.onBlur) {
        props.onBlur();
      }

      if (isEmpty(entitySelected)) {
        typeaheadRef._typeahead.clear();
        setEntitySelected([]);

        if (!props.showRecentViewed) {
          setEntityOptions([]);
        } else {
          const inputObj = e.target;
          adjustClassName('', inputObj);
        }
      }
    } catch (err) {
      console.log('AtsTypeAheadSearchSelect typeaheadOnBlur', err);
    }
  };

  const typeaheadOnChange = (selected) => {
    // ATS-1278 -  if a city is not in the list of cities, then force the zip code to be a required field
    // check if it's city field, then find the zipcode
    try {
      let zipCodeInput = null;
      let zipCodeLabel = null;
      let { postalCode = false} = props.requiredFields || {};
      const { isRemote = false } = props;

      if (isCity) {
        let zipCodeId = (props.id || '').replace('city', 'postalCode');
        if (props.id === zipCodeId) {
          zipCodeId = (props.id || '').replace('City', 'postalCode');
        }

        if (props.id !== zipCodeId) {
          zipCodeInput = document.getElementById(zipCodeId);
          zipCodeLabel = zipCodeInput ? zipCodeInput.previousElementSibling : null;
        }
      }

      if (selected.length === 0) {
        setEntitySelected([]);
        props.callback({[props.name]: props.isMulti ? [] : null});

        // some areas only have city, state, no zipcode
        // don't run this code if postCode is always required (like placement) or remote job
        if ((!postalCode && !isRemote) && isCity && zipCodeInput) {
          zipCodeInput.required = false;
          zipCodeLabel.innerHTML = 'Postal Code / Zip';
        }
      } else {
        setEntitySelected(selected);

        if (isCity) {
          props.callback({
            [props.name]: selected[0].city,
            [props.name.replace('city', 'state').replace('City', 'State')]: selected[0].state
          });

          // don't run this code if postCode is always required (like placement) or remote job
          if ((!postalCode && !isRemote) && zipCodeInput) {
            if (isEmpty(keyBy(entityOptions, 'city')[selected[0].city])) {
              zipCodeInput.required = true;
              zipCodeLabel.innerHTML = 'Postal Code / Zip <span class=\'required\'>*</span>';
            } else {
              zipCodeInput.required = false;
              zipCodeLabel.innerHTML = 'Postal Code / Zip';
            }
          }
        } else if (isState) {
          props.callback({[props.name]: selected[0].state});
        } else {
          props.callback({[props.name]: props.isMulti ? selected : selected[0]});
        }

        if(props.showRecentViewed) {
          const eventProperties = { object: (props.entityType || '').toLowerCase()};
          if(selected[0].recentViewedEntity) {
            eventProperties.action = 'select recent';
          } else {
            eventProperties.action = 'search';
          }
          window.amplitudeLogEvent('search entity field', eventProperties);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const filterByCallback = (option) => {
    try {
      if (isMulti || excludeOptions.length > 0) {
        // main entity object like candidate/contact/company
        const selectedEntityIds = entitySelected.concat(excludeOptions).map((selected) => get(selected, 'entityId') || get(selected, (entityType || '').toLowerCase() + 'Id'));
        if (selectedEntityIds.includes(option.entityId)) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } catch (err) {
      console.log(err);
      return true;
    }
  };

  const onFocus = (e) => {
    try {
      if(props.onFocus) {
        props.onFocus();
      }

      if (!props.showRecentViewed || !isEmpty(props.defaultValue)) {
        return false;
      }

      const inputObj = e.target;
      adjustClassName('', inputObj);
    } catch (err) {
      console.log(err);
    }
  };

  const onInputChange = (inputValue, e) => {
    try {
      if (!props.showRecentViewed) {
        return false;
      }

      const inputObj = e.target;
      adjustClassName(inputValue, inputObj);
    } catch (err) {
      console.log(err);
    }
  };

  function adjustClassName(inputValue, inputObj) {
    try {
      const ulObj = inputObj.closest('.rbt');

      if (inputValue) {
        ulObj.classList.remove('recent');
      } else {
        setEntityOptions(defaultRecentViewedOptions);
        if (!isEmpty(defaultRecentViewedOptions)) {
          ulObj.classList.add('recent');
        }
      }
    } catch (err) {
      console.log(err);
    }
  }


  // need to provide the remote option for the job
  const typeaheadProps = {
    multiple: props.isMulti,
    disabled: props.disabled === undefined ? false : props.disabled,
    selected: entitySelected,
    required: !props.required,
    isInvalid: props.required && isEmpty(entitySelected) && (props.formValidated || props.validated),
    clearButton: props.clearButton === undefined ? true : props.clearButton,
    id: props.id,
    name: props.name,
    align: 'justify',
    minLength: props.minLength || searchConst.minLength,
    options:
      entityType === 'COMPANY' && !isEmpty(props.companyTypes) ?
        (entityOptions || []).filter(item => props.companyTypes.includes(item.companyType))
        :
        (props.name === 'locations' && props.minLength === 0 ? [{location: 'Remote'}].concat(entityOptions) : entityOptions),
    highlightOnlyResult: false,
    Highlighter: true,
    placeholder: props.placeholder ? props.placeholder : 'Type to search',
    promptText: 'Type to search...',
    searchText: 'Searching...',
    className: props.className,
    allowNew: props.allowNew
  };

  return (
    <>
      {props.nonAsync ?
        <Typeahead
          {...typeaheadProps}
          ref={(ref) => typeaheadRef._typeahead = ref}
          labelKey={props.labelKey ? props.labelKey : (option) => `${utils.prepareTypeAheadLabelKey(option)}`}
          options={props.options}
          onBlur={(e) => typeaheadOnBlur(e)}
          onChange={(selected) => typeaheadOnChange(selected)}
          filterBy={props.filterBy ? (props.filterBy || []) : filterByCallback}
          renderMenuItemChildren={(option, props) => {
            return <QuickSearchItem item={option} searchTerm={props.text} usedFor='typeahead'/>;
          }}
        />
        :
        <AsyncTypeahead
          {...typeaheadProps}
          ref={(ref) => typeaheadRef._typeahead = ref}
          labelKey={props.labelKey ? props.labelKey : (option) => `${utils.prepareTypeAheadLabelKey(option)}`}
          minLength={isEmpty(defaultRecentViewedOptions) && !has(props, 'minLength') ? searchConst.minLength : 0}
          isLoading={isLoading}
          delay={props.delay === undefined ? searchConst.delay : props.delay}
          onSearch={typeaheadOnSearch}
          onBlur={(e) => typeaheadOnBlur(e)}
          onChange={(selected) => typeaheadOnChange(selected)}
          onFocus={(e) => onFocus(e)}
          onInputChange={(inputValue, e) => onInputChange(inputValue, e)}
          filterBy={(props.searchOperation === 'locationsSearch' || props.filterBy) ? (props.filterBy || []) : filterByCallback}
          renderMenuItemChildren={(option, props) => {
            return (option.entityType && !option.listId && !option.savedSearchId?
              <QuickSearchItem item={option} searchTerm={props.text} usedFor='typeahead'/>
              :
              <>
                {option.entityType && (option.listId || !option.savedSearchId) ?
                  <AtsHighlighter search={props.text} target={option.name} />
                  :
                  <AtsHighlighter search={props.text} target={option.location || compact([option.city, option.state]).join(', ') || option.name} />
                }
              </>
            );
          }}
        />
      }
      <input id={`hidden-${props.id}`}
        tabIndex={-1}
        style={{
          opacity: 0,
          height: 0,
          position: 'absolute'
        }}
        defaultValue={entitySelected}
        required={props.required}
      />
    </>
  );
};

AtsTypeAheadSearchSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};
