import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import {compact, get, isEmpty} from '../../../libs/lodash';
import searchConst from '../../../constants/searchConst';
import QuickSearchItem from '../QuickSearchItem';
import React, {useState} from 'react';
import utils from '../../../libs/utils';
import ApiClient from '../../../services/ApiClient';

export default ({entity, labelName, id, fieldName, formValidated, handleChange, required=true}) => {
  const typeaheadRef = {};
  const [isLoading, setIsLoading] = useState(false);
  const filterBy = searchConst.entityTypeAheadFilterBy;
  const [entitySelected, setEntitySelected] = useState(!isEmpty(entity) ? [entity]:[]);
  const [entityOptions, setEntityOptions] = useState(!isEmpty(entity) ? [entity]:[]);

  const fetchEntity = async (selectedEntity) => {
    try {
      const entityType = selectedEntity.entityType || selectedEntity.type;
      const entityId = selectedEntity.entityId || selectedEntity.id;
      const res = await ApiClient[searchConst.entityMap[entityType.toLowerCase()]+'Details']({id: +entityId});
      return res.body;
    } catch (error) {
      console.log(error);
      return selectedEntity;
    }
  };

  const typeaheadOnSearch = async (query) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.searchQuick({query});
      setEntityOptions((response.body || []).map((item) => {
        const entityType = (item.entityType || '').toLowerCase();
        item.entityId = +item[entityType+'Id'];
        item.searchId = '' + item[entityType+'Id'];
        item.searchStringId = '' + get(item, 'string' + utils.camelize(entityType) +'Id', '');

        filterBy.forEach(function(field) {
          item[field] = item[field] || '';
        });

        const {firstName, lastName} = item.candidateName || item.contactName || item;
        if (firstName || lastName) {
          item.fullName = compact([firstName, lastName]).join(' ');
        }

        return item;
      }));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setEntityOptions([]);
      setIsLoading(false);
    }
  };

  const typeaheadOnBlur = (e) => {
    e.preventDefault();
    if (isEmpty(entitySelected)) {
      typeaheadRef._typeahead.clear();
      setEntitySelected([]);
      setEntityOptions([]);
    }
  };

  const typeaheadOnChange = async (selected) => {
    if (selected.length === 0) {
      handleChange({'entity': ''});
      setEntitySelected([]);
      setEntityOptions([]);
    } else {
      const entity = await fetchEntity(selected[0]);
      const mergedEntity = {...selected[0], ...entity};
      const notifyTargetIds = utils.prepareNotifyTargetIds(entity);
      handleChange({'entity': mergedEntity, notifyTargetIds});
      setEntitySelected(selected);
    }
  };

  return (
    <>
      <label htmlFor='entity' style={{paddingBottom: '6px'}}>
        {labelName}
        {required ? <span className='required'>*</span> : ''}
      </label>
      <AsyncTypeahead
        ref={(ref) => typeaheadRef._typeahead = ref}
        // defaultInputValue={entity.primaryLink || entity.name || entity.jobTitle || utils.shortName(entity) || ''}
        selected={entitySelected}
        required={required}
        isInvalid={required && isEmpty(entity) && formValidated}
        clearButton={true}
        id={id}
        name={fieldName}
        align='justify'
        labelKey={(option) => {
          if (option.name) {
            return option.name;
          }
          switch ((option.entityType || option.type || '').toLowerCase()) {
          case 'candidate':
          case 'contact':
            return `${option.firstName} ${option.lastName}`;
          case 'job':
          case 'company':
            return `${option.jobTitle || option.name}`;
          case 'placement':
            const candidateName = option.candidateName;
            return `${candidateName.firstName} ${candidateName.lastName}`;
          default:
            return '';
          }
        }}
        minLength={searchConst.minLength}
        isLoading={isLoading}
        options={entityOptions}
        highlightOnlyResult={false}
        Highlighter={true}
        delay={searchConst.delay}
        onSearch={typeaheadOnSearch}
        onBlur={(e) => typeaheadOnBlur(e)}
        onChange={(selected) => typeaheadOnChange(selected)}
        placeholder='Type to search'
        promptText='Type to search...'
        searchText='Searching...'
        // filterBy={filterBy}
        filterBy={(option, props) => true}
        renderMenuItemChildren={(option, props) => (
          <QuickSearchItem item={{...option, entityType: option.entityType || option.type}} searchTerm={props.text} usedFor='typeahead' />
        )}
      />
    </>
  );
};
