import './style.scss';
import React, {useEffect, useState} from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import {toast} from 'react-toastify';
import {useDispatch,  useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {Button, QuickSearchItem, AtsSelect, AtsRichTextArea, FormFields, AtsCheckBox} from '../../common';
import SubmissionRateFields from './SubmissionRateFields';
import SubmissionStages from '../Stages/SubmissionStages';

import useForm from '../../../hooks/useForm';
import useLookup from '../../../hooks/useLookup';
import useNotifyUserIds from '../../../hooks/useNotifyUserIds';

import searchConst from '../../../constants/searchConst';
import submissionConst from '../../../constants/submissionConst';
import {get, isEmpty} from '../../../libs/lodash';
import utils from '../../../libs/utils';
import { prepareForRenderSubmissionActivity, prepareForApiSubmission } from '../libs/utils';
import {prepareForRenderJobs} from '../../Jobs/libs/utils';
import { prepareForRenderCandidates } from '../../Candidates/libs/utils';

import * as activityActions from '../../../actions/activity';
import * as submissionActions from '../../../actions/submission';
import * as searchEntityActions from '../../../actions/searchEntity';

import apiUserStatus from '../../../services/apiUserStatus';
import ApiClient from '../../../services/ApiClient';

export default function SubmissionForm(props) {
  const currentUser = apiUserStatus.get() || {};
  const { userId = null } = currentUser;
  const defaultJob = get(props, 'entity.entityType', '').toUpperCase() === 'JOB' ? [{jobTitle: props.entity.primaryLink, jobId: props.entity.entityId, entityType: 'JOB', entityId: props.entity.entityId, }] : [];
  const defaultCandidate = get(props, 'entity.entityType', '').toUpperCase() === 'CANDIDATE' ? [{fullName: props.entity.primaryLink, candidateId: props.entity.entityId, entityType: 'CANDIDATE', entityId: props.entity.entityId, }] : [];
  const [defaultSubmission] = useState({
    stage: submissionConst.stages[0],
    candidateIds: (props.defaultCandidates || []).map((c) => c.candidateId),
    jobIds: (props.defaultJobs || []).map((c) => c.jobId),
    ownerUserId: userId,
  });
  const recentViewedCandidates = get(currentUser, 'recentViewedEntities.CANDIDATE') || [];
  const recentViewedJobs = get(currentUser, 'recentViewedEntities.JOB') || [];
  const [candidateRecruiterUserId, setCandidateRecruiterUserId] = useState(props.candidateRecruiterUserId);
  const [jobOwnerUserId, setJobOwnerUserId] = useState(props.jobOwnerUserId);
  const [jobRecruiterUserId, setJobRecruiterUserId] = useState(props.jobRecruiterUserId);
  const [submission, setSubmission] = useState(defaultSubmission);
  const [isLoading, setIsLoading] = useState(false);
  const [candidateOptions, setCandidateOptions] = useState(
    isEmpty(props.defaultCandidates ? props.defaultCandidates : defaultCandidate) ? (
      recentViewedCandidates
    ) : (
      props.defaultCandidates ? props.defaultCandidates : defaultCandidate
    )
  );
  const [jobOptions, setJObOptions] = useState(
    isEmpty(props.defaultJobs ? props.defaultJobs : defaultJob) ? (
      recentViewedJobs
    ) : (
      props.defaultJobs ? props.defaultJobs : defaultJob
    )
  );
  const [jobsSelected, setJobsSelected] = useState(props.defaultJobs ? props.defaultJobs : defaultJob);
  const [jobDetail, setJobDetail] = useState({});
  const [candidatesSelected, setCandidatesSelected] = useState(defaultCandidate ? defaultCandidate : props.defaultCandidate);
  const filterBy = searchConst.entityTypeAheadFilterBy;

  const typeaheadRef = {};
  const lookups = useLookup({names: ['users', 'teams', 'businessUnits', 'practiceAreas', 'badges', 'industries', 'departments', 'jobFunctions', 'skills', 'phcCareerCodes', 'phcFacilityTypes']});
  const dispatch = useDispatch();
  const submissionCriteria = useSelector(state => state.submissionReducer.parameters.criteria);
  const activityParams = useSelector(state => state.activityReducer.parameters);

  const [taskCreation, setTaskCreation] = useState(false);

  useEffect(() => {
    let cancel = false;
    try {
      const jobIdsArray = props.defaultCandidates ? props.defaultCandidates.map(candidate => candidate.jobId) : [];
      const apiCall = async () => {
        if (!cancel) {
          const res = await ApiClient.jobsDetails({id: props.entity.entityId});
          if (!cancel) {
            const job = prepareForRenderJobs(res.body);
            // ATS - 2332 - Dont Autofill Submission Rates from the Job
            submissionConst.notAutoFillFields.forEach((field) => {
              delete job.rateInfo[field];
            });
            props.type === 'job' ? (
              setSubmission({
                ...defaultSubmission,
                jobIds: [props.defaultJobs.candidateId],
                rateInfo: job.rateInfo,
                // businessUnitId: job.businessUnitId
              })
            ) : (
              setSubmission({
                ...defaultSubmission,
                jobIds: [props.entity.entityId],
                rateInfo: job.rateInfo,
                // businessUnitId: job.businessUnitId
              })
            );
            setJobDetail(job);
          }
        }
      };

      if (props.entity && (props.entity.entityType === 'CANDIDATE' || props.entity.entityType === 'candidate')) {
        props.type === 'job' ? (
          setSubmission({
            ...defaultSubmission,
            jobIds: jobIdsArray.filter((candidateId, index) => jobIdsArray.indexOf(candidateId) === index),
            candidateIds: props.defaultJobs.map(job => job.candidateId),
            // KEYs CHANGED
            // candidateIds: props.defaultCandidates.map(candidate => candidate.jobId),
            // jobIds: props.defaultJobs.map(job => job.candidateId),
          })
        ) : (
          setSubmission({
            ...defaultSubmission,
            candidateIds: [props.entity.entityId],
          })
        );
      } else if (props.entity && props.entity.entityType === 'JOB') {
        apiCall();
      }
    } catch (error) {
      console.log(error);
    }

    return () => {
      cancel = true;
    };
  }, [props.entity, defaultSubmission, props.defaultCandidates, props.defaultJobs, props.type]);

  const closeSubmission = () => {
    props.modalCancel();
    if(props.closeDrawer) {
      props.closeDrawer();
    }
  };

  const prepareMsg = (scope, data) => {
    if (scope === 'SINGLE_SUBMIT' && data.length === 0) {
      return 'Submission already exists';
    } else if (scope === 'SINGLE_SUBMIT' && data.length > 0) {
      return 'Submission added';
    } else if (scope === 'MASS_SUBMIT' && data.length === 0) {
      return 'All Submissions already exist';
    } else if (scope === 'MASS_SUBMIT' && data.length > 0) {
      return `${data.length} Submissions added`;
    }
  };

  const submitSubmission = async () => {
    try {
      const parameters = prepareForApiSubmission(values);
      const response = await ApiClient.submissionsCreate({}, parameters.submission);
      const data = response.body;
      const submissionActivity = prepareForRenderSubmissionActivity(data);

      if(props.saveCallback) {
        props.saveCallback(submissionActivity, get(props.entity, 'entityType'));
      } else {
        window.amplitudeLogEvent('create', { action: 'save new submission', object: 'submission', type: submissionActivity.activityType.name.toLowerCase(), globalNav: 'global add', method: 'global add', timer : 'end'});
      }

      if (activityParams && activityParams.entityType && (activityParams.entityType.toUpperCase() === 'JOB' || activityParams.entityType.toUpperCase() === 'CANDIDATE')) {
        const entityTypeLowerCase = activityParams.entityType.toLowerCase();
        const entityPath = '/' + entityTypeLowerCase;
        if (window.location.pathname.startsWith(entityPath) && values[entityTypeLowerCase+'Ids'].includes(+activityParams.entityId)){
          dispatch(submissionActions.fetchSubmission({offset: 0, maxResults: submissionConst.submissionTabMaxResults, criteria: submissionCriteria}));
          dispatch(searchEntityActions.getMetadata({entityType: entityTypeLowerCase, id: activityParams.entityId}));

          const activityFilterCategories = utils.getActivityFilterCategories(entityTypeLowerCase);
          if(activityFilterCategories && activityFilterCategories?.includes('SUBMISSION')) {
            dispatch(activityActions.fetchRecentActivities());
          }
        }
      }

      const msg = prepareMsg(props.scope, data);
      toast(msg, {
        className: 'toast-response-background',
        bodyClassName: 'toast-response-body',
        position: 'top-right'
      });

      // jump to create placement page and auto fill with related info
      const candidateId = get(values, 'candidateIds[0]');
      const jobId = get(values, 'jobIds[0]');
      dispatch(submissionActions.createNewPlacement({stage: get(values, 'stage'), candidateId, jobId, history: props.history}));

      if (props.createTaskFromData && taskCreation) {
        const entity = candidatesSelected[0];
        props.createTaskFromData({...values, entity});
      } else {
        closeSubmission();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const typeaheadOnSearch = async (query, entityType) => {
    if (query && query.length < searchConst.minLength) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await ApiClient.searchQuick({query, entityType});
      const optionsResult = (response.body || []).map((item) => {
        item.entityType = item.entityType ? item.entityType : entityType;
        item.entityId = item.entityId ? +item.entityId : +item[entityType.toLowerCase() + 'Id'];
        item.searchId = '' + (item.entityId || '');
        item.searchStringId = '' + get(item, 'string' + utils.camelize(item.entityType) + 'Id', '');

        filterBy.forEach(function (field) {
          item[field] = item[field] || '';
        });

        if (item.firstName || item.lastName) {
          item.fullName = item.firstName + ' ' + item.lastName;
        }

        return item;
      }).filter((item) => {
        const jobCLosedStatus = utils.convertIdToName(lookups, 'jobOpenStatuses', 'CLOSED');
        if (entityType === 'JOB' && jobsSelected.length > 0) {
          return item.entityId !== jobsSelected[0].entityId && item.openStatusValue !== jobCLosedStatus;
        } else if (entityType === 'CANDIDATE' && candidatesSelected.length > 0) {
          return item.entityId !== candidatesSelected[0].entityId;
        } else {
          return entityType === 'JOB' ? item.openStatusValue !== jobCLosedStatus : true;
        }
      });

      if (entityType==='CANDIDATE') {
        setCandidateOptions(optionsResult);
      } else {
        setJObOptions(optionsResult);
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const typeaheadOnBlur = (e) => {
    if (isEmpty(values.candidateIds)) {
      typeaheadRef.candidate.clear();
      setCandidatesSelected([]);
      setCandidateOptions(recentViewedCandidates);
    }
    if (isEmpty(values.jobIds)) {
      typeaheadRef.job.clear();
      setJobsSelected([]);
      setJObOptions(recentViewedJobs);
      setJobDetail({});
    }
  };

  const typeaheadOnChange = async (selected, entityType) => {
    if (selected.length > 0) {
      const tempIds = selected.map((item) => +item.entityId);
      if (entityType === 'JOB') {
        const res = await ApiClient.jobsDetails({id: selected[0].jobId});
        const job = prepareForRenderJobs(res.body);
        // ATS - 2332 - Dont Autofill Submission Rates from the Job
        submissionConst.notAutoFillFields.forEach((field) => {
          delete job.rateInfo[field];
        });
        setJobOwnerUserId(get(job, 'ownerUserId'));
        setJobRecruiterUserId(get(job, 'primaryRecruiterUserId'));
        handleNotifyUsersChange({
          rateInfo: job.rateInfo,
          jobIds: tempIds,
          'jobOwnerUserId': (values.stage === 'PRE_SUBMISSION' ? null : get(job, 'ownerUserId')),
          'jobRecruiterUserId': (values.stage === 'PRE_SUBMISSION' ? null  : get(job, 'primaryRecruiterUserId'))
        });
        setJobsSelected(selected);
        setJobDetail(job);
      } else {
        const res = await ApiClient.candidatesDetails({id: selected[0].candidateId});
        const candidate = prepareForRenderCandidates(res.body);
        setCandidateRecruiterUserId(get(candidate, 'recruiterUserId'));
        handleNotifyUsersChange({
          candidateIds: tempIds,
          'candidateRecruiterUserId': values.stage === 'PRE_SUBMISSION' ? null : get(candidate, 'recruiterUserId')
        });

        setCandidatesSelected(selected);
      }

      const eventProperties = { object: (entityType || '').toLowerCase()};
      if(selected[0].recentViewedEntity) {
        eventProperties.action = 'select recent';
      } else {
        eventProperties.action = 'search';
      }
      window.amplitudeLogEvent('search entity field', eventProperties);
    } else {
      if (entityType === 'JOB') {
        handleChange({rateInfo: null, jobIds: []});
        setJobsSelected([]);
        setJObOptions(recentViewedJobs);
        setJobDetail({});
      } else {
        handleChange({candidateIds: []});
        setCandidatesSelected([]);
        setCandidateOptions(recentViewedCandidates);
      }
    }
  };

  const onFocus = (e, entityType) => {
    try {
      if (!isEmpty(props.defaultValue)) {
        return false;
      }

      const inputObj = e.target;
      adjustClassName('', inputObj, entityType);
    } catch (err) {
      console.log(err);
    }
  };

  const onInputChange = (inputValue, e, entityType) => {
    try {
      if (!props.showRecentViewed) {
        return false;
      }

      const inputObj = e.target;
      adjustClassName(inputValue, inputObj, entityType);
    } catch (err) {
      console.log(err);
    }
  };

  const onHandleStageChange = (updateValue) => {
    handleNotifyUsersChange({
      ...updateValue,
      'candidateRecruiterUserId': updateValue.stage === 'PRE_SUBMISSION' ? null : candidateRecruiterUserId,
      'jobOwnerUserId': updateValue.stage === 'PRE_SUBMISSION' ? null : jobOwnerUserId,
      'jobRecruiterUserId': updateValue.stage === 'PRE_SUBMISSION' ? null : jobRecruiterUserId
    });
  };

  function adjustClassName(inputValue, inputObj, entityType) {
    try {
      const ulObj = inputObj.closest('.rbt');
      const selected = entityType === 'JOB' ? jobsSelected : candidatesSelected;
      const recentViewedEntries = entityType === 'JOB' ? recentViewedJobs : recentViewedCandidates;

      if (inputValue || !isEmpty(selected)) {
        ulObj.classList.remove('recent');
      } else {
        if (entityType === 'JOB') {
          setJObOptions(recentViewedJobs);
        } else {
          setCandidateOptions(recentViewedCandidates);
        }
        if (!isEmpty(recentViewedEntries)) {
          ulObj.classList.add('recent');
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  const {values, formValidated, handleChange, handleSubmit} = useForm(submission, submitSubmission);

  const {notifyTargetIdsField, handleNotifyUsersChange} = useNotifyUserIds({lookups, handleChange, entity: values});

  return (
    <>
      <form name='submission-form' noValidate onSubmit={handleSubmit} autoComplete='off'>
        <div className='form-group'>
          <label htmlFor='submission-stage'>
            Stage
          </label>
          <div>
            <SubmissionStages
              stage={values.stage}
              handleChange={onHandleStageChange}
            />
          </div>
        </div>
        <div className='form-group d-flex'>
          <div className='col-5 px-0'>
            <label htmlFor='submission-candidate'>
              Candidate
              <span className='required'>*</span>
            </label>
            <AsyncTypeahead
              multiple={props.scope === 'MASS_SUBMIT'}
              ref={(ref) => typeaheadRef.candidate = ref}
              defaultSelected={
                props.type === 'job' ? (
                  [
                    {
                      candidateId: [props.candidate.candidateId],
                      enntityId: [props.candidate.candidateId],
                      entityType: 'CANDIDATE',
                      fullName: [`${props.candidate.firstName} ${props.candidate.lastName}`]
                    },
                  ]
                ) : candidatesSelected
              }
              required={true}
              isInvalid={isEmpty(values.candidateIds) && formValidated}
              clearButton={true}
              id='submission-candidate'
              name='submission-candidate'
              align='justify'
              labelKey={(option) => `${utils.prepareTypeAheadLabelKey(option)}`}
              minLength={isEmpty(recentViewedCandidates) ? searchConst.minLength : 0}
              isLoading={isLoading}
              options={candidateOptions}
              highlightOnlyResult={false}
              Highlighter={true}
              delay={searchConst.delay}
              onSearch={(query) => typeaheadOnSearch(query, 'CANDIDATE')}
              onBlur={(e) => typeaheadOnBlur(e)}
              onChange={(selected) => typeaheadOnChange(selected, 'CANDIDATE')}
              onFocus={(e) => onFocus(e, 'CANDIDATE')}
              onInputChange={(inputValue, e) => onInputChange(inputValue, e, 'CANDIDATE')}
              placeholder='Type to search'
              promptText='Type to search...'
              searchText='Searching...'
              // filterBy={filterBy}
              filterBy={(option, props) => (true)}
              renderMenuItemChildren={(option, props) => {
                return <QuickSearchItem item={option} searchTerm={props.text} usedFor='typeahead' />;
              }}
            />
          </div>
          <div className='col-2 px-0 center-section'>
            <div className='center-section-object'>Submit To</div>
          </div>
          <div className='col-5 px-0'>
            <label htmlFor='submission-job'>
              Job
              <span className='required'>*</span>
            </label>
            <AsyncTypeahead
              multiple={props.scope==='MASS_SUBMIT'}
              ref={(ref) => typeaheadRef.job = ref}
              defaultSelected={
                props.type === 'job' ? (
                  props.defaultCandidates
                ) : jobsSelected
              }
              required={true}
              isInvalid={isEmpty(values.jobIds) && formValidated}
              clearButton={true}
              id='submission-job'
              name='submission-job'
              align='justify'
              labelKey={(option) => `${option.jobTitle}`}
              minLength={isEmpty(recentViewedJobs) ? searchConst.minLength : 0}
              isLoading={isLoading}
              options={props.type === 'job' ? [] : jobOptions}
              highlightOnlyResult={false}
              Highlighter={true}
              delay={searchConst.delay}
              onSearch={(query) => typeaheadOnSearch(query, 'JOB' )}
              onBlur={(e) => typeaheadOnBlur(e)}
              onChange={(selected) => typeaheadOnChange(selected, 'JOB')}
              onFocus={(e) => onFocus(e, 'JOB')}
              onInputChange={(inputValue, e) => onInputChange(inputValue, e, 'JOB')}
              placeholder='Type to search'
              promptText='Type to search...'
              searchText='Searching...'
              // filterBy={filterBy}
              filterBy={(option, props) => true}
              renderMenuItemChildren={(option, props) => {
                return <QuickSearchItem item={option} searchTerm={props.text} usedFor='typeahead' />;
              }}
            />
          </div>
        </div>
        <div className='form-group col-5 px-0'>
          <label htmlFor='ownerUserId'>
            Submission Owner
            <span className='required'>*</span>
          </label>
          <AtsSelect
            id='ownerUserId'
            name='ownerUserId'
            defaultValue={values.ownerUserId}
            required={true}
            options={lookups.users || []}
            validated={formValidated}
            callback={handleNotifyUsersChange}
          />
        </div>
        <SubmissionRateFields scope={props.scope} job={jobDetail} rateInfo={values.rateInfo} callback={handleChange} />
        <div className='form-group'>
          <AtsRichTextArea
            entityType={'SUBMISSION'}
            label={<label>Add a Comment</label>}
            id='submission-auditLogComment'
            name={'auditLogComment'}
            required={false}
            initialValue={values.auditLogComment || ''}
            handleChange={handleChange}
          />
        </div>
        {/* Notify Users or Teams */}
        <FormFields
          fields={notifyTargetIdsField}
          formValidated={formValidated}
          callback={handleChange}
        />
        <div className='form-group'>
          <div className='d-flex justify-content-end pt-3'>
            <AtsCheckBox
              id={'submission-form-task-creation'}
              name='taskCreation'
              defaultValue={taskCreation?['CHECKED']:[]}
              options={[{label: 'Create a Task', value: 'CHECKED'}]}
              handleChange={() => setTaskCreation(!taskCreation)} />
            <Button type='button' id='close-submission-form' variant='btn-secondary mr-4' click={() => closeSubmission()}>
              Cancel
            </Button>
            <Button type="submit" id='save-submission' variant='btn-primary ats-md-btn'>
              Save
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}

SubmissionForm.propTypes = {
  entity: PropTypes.object,
  clearForm: PropTypes.func,
  saveCallback: PropTypes.func
};
