import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {QuickSearchItem, AtsErrorBoundary} from '../common';
import searchConst from '../../constants/searchConst';
import {get, isEmpty, isEqual} from '../../libs/lodash';
import utils from '../../libs/utils';
import usePrevious from '../../hooks/usePrevious';
import HeaderSearch from './HeaderSearch';
import SearchResult from '../../services/SearchResult';
import ApiClient from '../../services/ApiClient';

export default function QuickSearch(props) {
  const [isFetched, setIsFetched] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const prevSearchTerm = usePrevious(searchTerm);

  const [searchResult, setSearchResult] = useState([]);
  const [searchEntityTypes] = useState(searchConst.searchFilterTypes);
  const [searchEntityType, setSearchEntityType] = useState('');
  const prevEntityType = usePrevious(searchEntityType);

  const [filteredSearchResult, setFilteredSearchResult] = useState(searchResult);
  const prevSearchResult = useState(searchResult);

  useEffect(() => {
    if (searchTerm === '' && searchTerm !== prevSearchTerm) {
      const apiCall = async () => {
        try {
          const res = await ApiClient.searchesRecentList({'type': 'ITEM'});

          let tempEntityTypes = {'ALL': 'All'};
          res.body.forEach((item) => {
            tempEntityTypes[item.entityType]=utils.camelize(item.entityType);
          });
          // setSearchEntityTypes(orderEntityTypes(tempEntityTypes));
          setSearchEntityType('ALL');
          setSearchResult(res.body);
          setFilteredSearchResult(res.body);
        } catch (error) {
          console.log(error);
        }
      };
      apiCall();
    }
  }, [searchTerm, prevSearchTerm]);

  async function searchQuickRequest({query, entityType= searchEntityType}) {
    try {
      const response = await ApiClient.searchQuick({query, entityType: entityType !== 'ALL' ? entityType : null});

      setSearchResult(response.body);
      const filterResult = response.body.filter((item) => {
        return entityType !== 'ALL' ? item.entityType === entityType : true;
      });
      setFilteredSearchResult(entityType === 'ALL' || !entityType ? response.body : filterResult);

      setSearchTerm(query);
      setIsFetched(false);
    } catch (error) {
      console.log(error);
      setSearchResult([]);
      setIsFetched(false);
    }
  }

  let timeout = null;
  const handleChange = async (event) => {
    const query = ( get(event, 'target.value', '') || '').trim();
    clearTimeout(timeout);

    if(!query) {
      if (searchTerm !== prevSearchTerm) {
        setSearchResult([]);
      }
      setSearchTerm(query);
      return;
    }

    if (query && query.length >= searchConst.minLength) {
      timeout = setTimeout(async () => {
        window.amplitudeLogEvent('quick search', {action: 'text search'});
        if (!isFetched) {
          setIsFetched(true);
          await searchQuickRequest({query});
        }
      }, 1000);

      setIsFetched(false);
    }
  };

  const handleFilter = async (name, value) => {
    window.amplitudeLogEvent('quick search', {action: 'filter', filterValue: (value || '').toLowerCase(), object: searchEntityType});
    setSearchEntityType(value);
    if (searchTerm) {
      await searchQuickRequest({query: searchTerm, entityType: value});
    }
  };

  useEffect(() => {
    if (!searchTerm && searchEntityType !== prevEntityType && !isEqual(searchResult, prevSearchResult) ) {
      const filterResult = searchResult.filter((item) => {
        return item.entityType === searchEntityType.toUpperCase();
      });

      setFilteredSearchResult(searchEntityType=== 'ALL' ? searchResult : filterResult);
    }
  }, [searchEntityType, prevEntityType, searchResult, prevSearchResult, searchTerm]);

  const onWheelHandle = () => {
    try {
      const scrollPosition = document.documentElement.scrollTop;
      if (scrollPosition) {
        window.scrollTo(0, 0);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderQuickSearchItems = () => {
    return (
      <div id='quick-search-items' onWheel={() => onWheelHandle()}>
        {isEmpty(SearchResult.prepareForQuickSearch(searchResult)) ?
          <div className='d-flex item pl-4 ml-4'>No results found.</div>
          :
          filteredSearchResult.map((item, index) =>
            <QuickSearchItem
              key={`quick-search-item-${item.entityType}-${index}`}
              item={{...item, entityType: item.entityType}}
              searchTerm={searchTerm}
              usedFor={searchTerm ? 'quicksearch' : 'recentsearch'}
              filterValue='all'
              closeDrawer={props.closeDrawer}
            />
          )
        }
      </div>
    );
  };

  const submitQuickSearch = (e) => {
    e.preventDefault();
  };

  return (
    <div id='quick-search-drawer' className='pb-4'>
      <div className='divider divider-horizontal divider-100 my-4' />

      {/*{ add form to avoid browser auto fill}*/}
      <form onSubmit={(e) => submitQuickSearch(e)} autoComplete='off'>
        <HeaderSearch
          drawer={props.drawer}
          closeDrawer={props.closeDrawer}
          searchTerm={searchTerm}
          handleChange={handleChange}
          handleFilter={handleFilter}
          searchEntityTypes={searchEntityTypes}
          searchEntityType={searchEntityType}
          isFetched={isFetched}
        />
      </form>
      <div className='divider divider-horizontal divider-100 my-4' />

      <div className='px-4 pt-4'>
        <AtsErrorBoundary>
          {renderQuickSearchItems()}
        </AtsErrorBoundary>
      </div>
    </div>
  );
}

QuickSearch.propTypes = {
  closeDrawer: PropTypes.func.isRequired
};
