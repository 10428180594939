const activityAuthor = {id: 'ACTIVITY_AUTHOR', name: 'Activity Author', value: 'ACTIVITY_AUTHOR', label: 'Activity Author'};
const owner = {id: 'OWNER', name: 'Owner', value: 'OWNER', label: 'Owner'};
const recruiter = {id: 'RECRUITER', name: 'Recruiter', value: 'RECRUITER', label: 'Recruiter'};
const coRecruiter = {id: 'CO_RECRUITER', name: 'Co-Recruiter', value: 'CO_RECRUITER', label: 'Co-Recruiter'};
const userFieldLookups = {
  'CANDIDATE': [activityAuthor, owner],
  'COMPANY': [activityAuthor, owner, {id: 'CO_OWNER', name: 'Co-Owner', value: 'CO_OWNER', label: 'Co-Owner'}, recruiter,  coRecruiter],
  'CONTACT': [activityAuthor, owner],
  'JOB': [activityAuthor, owner, recruiter, coRecruiter],
  'PLACEMENT': [
    activityAuthor, recruiter,  coRecruiter, owner, 
    {id: 'SALESPERSON', name: 'Salesperson', value: 'SALESPERSON', label: 'Salesperson'},
    {id: 'CO_SALESPERSON', name: 'Co-Salesperson', value: 'CO_SALESPERSON', label: 'Co-Salesperson'},
    {id: 'BACK_OFFICE_OWNER', name: 'Back Office Owner', value: 'BACK_OFFICE_OWNER', label: 'Back Office Owner'}
  ],
  'SUBMISSION': [
    activityAuthor,
    {id: 'OWNER', name: 'Submission Owner', value: 'OWNER', label: 'Submission Owner'},
    {id: 'SUBMISSION_CANDIDATE_OWNER', name: 'Candidate Owner', value: 'SUBMISSION_CANDIDATE_OWNER', label: 'Candidate Owner'},
    {id: 'SUBMISSION_JOB_OWNER', name: 'Job Owner', value: 'SUBMISSION_JOB_OWNER', label: 'Job Owner'},
    {id: 'SUBMISSION_JOB_RECRUITER', name: 'Job Recruiter', value: 'SUBMISSION_JOB_RECRUITER', label: 'Job Recruiter'},
    {id: 'SUBMISSION_JOB_CO_RECRUITER', name: 'Job Co-Recruiter', value: 'SUBMISSION_JOB_CO_RECRUITER', label: 'Job Co-Recruiter'}
  ],
  'DEAL': [activityAuthor, owner]
};

const dateFieldForAll = [
  {id: 'ACTIVITY_DATE', name: 'Activity Date'},
  {id: 'DATE_ADDED', name: 'Date Added'},
  {id: 'DATE_UPDATED', name: 'Date Last Updated'}
];

const dateFieldLookups = {
  'CANDIDATE': dateFieldForAll,
  'COMPANY': dateFieldForAll,
  'CONTACT': dateFieldForAll,
  'JOB': dateFieldForAll.concat([
    {id: 'START_DATE', name: 'Start Date'},
    {id: 'END_DATE', name: 'End Date'}
  ]),
  'PLACEMENT': dateFieldForAll.concat([
    {id: 'START_DATE', name: 'Start Date'},
    {id: 'ACTIVE_DURING', name: 'Active During'},
    {id: 'END_DATE', name: 'End Date'},
    {id: 'EFFECTIVE_DATE', name: 'Effective Date'},
    {id: 'CLOSED_DATE', name: 'Closed Date'}
  ]),
  'SUBMISSION': dateFieldForAll,
  'DEAL': dateFieldForAll
};


const entityConst= {
  entityTypeOptions: [
    {id: 'ALL', name: 'All', value: 'ALL',  label: 'All'},
    {id: 'CANDIDATE', name: 'Candidates', value: 'CANDIDATE',  label: 'Candidates'},
    {id: 'COMPANY', name: 'Companies', value:  'COMPANY', label: 'Companies'},
    {id: 'CONTACT', name: 'Contacts', value: 'CONTACT', label: 'Contacts'},
    {id: 'JOB', name: 'Jobs', value: 'JOB', label: 'Jobs'},
    {id: 'PLACEMENT', name: 'Placements', value: 'PLACEMENT', label: 'Placements'},
    {id: 'SUBMISSION', name: 'Submissions', value: 'SUBMISSION', label: 'Submissions'},
    {id: 'DEAL', name: 'Deals', value: 'DEAL', label: 'Deals'}
  ],
  userFieldLookups: userFieldLookups,
  dateFieldLookups: dateFieldLookups
};

export default entityConst;
