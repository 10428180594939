import React, {useEffect, useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import AtsEditor from '../AtsEditor';
import AtsEffectiveDatePicker from '../AtsEffectiveDatePicker';
import NotificationSelection from '../../Notifications/NotificationSelection';
import {FormFields} from '../index';
import utils from '../../../libs/utils';
import {get} from '../../../libs/lodash';
import {prepareEffectiveDateAsMandatory} from '../../Placements/libs/utils';

import './style.scss';

export default function AtsInlineEdit (props) {
  const originValue = useMemo(() => (props.props.value ? get(props.props, 'value') : get(props.props, 'defaultValue')) || null, [props.props]);
  const [formValidated, setFormValidated] = useState(false);
  const [currValue, setCurrValue] = useState(originValue);
  const [currJson, setCurrJson] = useState({});
  const isEdit = props.editable !== false && props.inlineEdit.isEdit && props.inlineEdit.section === props.props.name;
  const isPlacement = !!props.placementId;
  const isList = (props.props || {}).name && props.props.name === 'lists';

  useEffect(() => {
    setCurrValue(originValue);
  }, [ originValue ]);
  
  const handleOnCancel = (event) => {
    event.preventDefault();
    setCurrValue(originValue);
    props.handleCancel();
  };

  const handleOnChange = (updateData) => {
    setCurrValue(updateData[props.props.name]);
    setCurrJson(updateData);
    if(isPlacement) {
      prepareEffectiveDateAsMandatory(props.values, updateData, props.handleChange);
      return;
    }
    if(isList) {
      props.handleChange(updateData);
    }
  };

  const handleOnSave = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const buttonSelector = 'button[type=\'submit\']';

    try {
      setFormValidated(true);
      if (form.checkValidity() === true) {
        if (props.saveCallback && typeof props.saveCallback === 'function') {
          utils.prepareDisabledButton(form, buttonSelector, true);
          if (['primaryContact.contactId', 'contact.contactId', 'programInfo.mspContact.contactId', 'programInfo.clientContact.contactId'].includes(props.props.name)) {
            if (props.effectiveDate) {
              await props.saveCallback({...currJson, effectiveDate: props.effectiveDate});
            } else {
              await props.saveCallback(currJson);
            }
          } else if (props.effectiveDate) {
            await props.saveCallback({
              [props.props.name]: currValue,
              effectiveDate: props.effectiveDate,
            });
          } else {
            await props.saveCallback({[props.props.name]: currValue});
          }
        }
      } else {
        form.classList.add('was-validated');
      }
    } catch (err) {
      console.log(err);
    } finally {
      utils.prepareDisabledButton(form, buttonSelector, false);
    }
  };


  const renderInlineEdit = () => {
    if (props.editable === false) {
      return (
        <>
          <div className='label'>{props.label}</div>
          <div className='value'>{props.displayValue}&nbsp;&nbsp;</div>
        </>
      );
    }

    if (isEdit) {
      return (
        <div className='ats-inline-edit'>
          <div className='label'>
            {props.label}
            {props.props.required && <span className='required'>*</span>}
          </div>
          { props.renderInactiveUsersCheckBox? props.renderInactiveUsersCheckBox('inactive-user-checkbox') : '' }
          <div className='value'>
            <form noValidate onSubmit={handleOnSave} autoComplete='off'>
              <props.component
                {...props.props}
                id={props.props.name}
                defaultValue={currValue}
                validated={formValidated}
                callback={handleOnChange}
              />

              { props.notifyInfo ? <FormFields fields={props.notifyInfo} formValidated={formValidated} callback={props.handleChange} /> : ''}

              { props.toggleModal ? <NotificationSelection toggleModal={props.toggleModal}/> : ''}

              {
                ( props.placementId && props.label !== 'Owner' )?
                  <>
                    { props.renderTaskCheckBox? props.renderTaskCheckBox('placement-task-creation') : '' }
                    <div className='text-right btn-inline-actions'>
                      <AtsEffectiveDatePicker
                        effectiveDate={props.effectiveDate}
                        handleChange={props.handleChange}
                        minDate={props.scheduledStartDate}
                        required={get(props.values, 'requiredEffectiveDate')}
                        handleOnCancel={handleOnCancel}
                      />
                    </div>
                  </>
                  :
                  <>
                    { props.renderTaskCheckBox? props.renderTaskCheckBox() : '' }
                    <div className='text-right btn-inline-actions'>
                      <Button type='button' id='cancel-inline' variant='mr-4' click={handleOnCancel}/>
                      <Button type='submit' id='save-inline'/>
                    </div>
                  </>
              }
            </form>
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div className={`label${props.label.includes(':') ? ' d-inline-block mb-4 pb-2' : '' }`}>{props.label}</div>
          <div className={`value${props.component === AtsEditor ? '' : ' d-inline-block'} ats-inline${props.label.includes(':') ? ' displayValueInline' : ''}`}>
            {props.component === AtsEditor ?
              <div className='mce-content-body'>
                <span className='float-right'>{props.editIcon(props.props.name)}</span>
                <span dangerouslySetInnerHTML={{__html:  props.displayValue || ''}} />
              </div>
              :
              <div className={`${props.className ? ' ' + props.className : ''}`}>{props.displayValue}{props.editIcon(props.props.name)}</div>
            }
          </div>
        </>
      );
    }
  };

  return renderInlineEdit();
}

AtsInlineEdit.propTypes = {
  label: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  props: PropTypes.object.isRequired
};
