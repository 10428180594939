import React from 'react';
import {Link} from 'react-router-dom';
import {
  cloneDeep,
  compact,
  filter,
  get,
  has,
  isEmpty,
  keyBy,
  invert,
  flattenDeep,
  uniq,
  isEqual
} from '../../../libs/lodash';
import settingConst from '../../../constants/settingConst';
import utils from '../../../libs/utils';
import ApiClient from '../../../services/ApiClient';
import Lookups from '../../../services/Lookups';
import searchConst from '../../../constants/searchConst';
import additionalSearchFieldsConfig from '../SearchModal/additionalSearchFieldsConfig';
import apiUserStatus from '../../../services/apiUserStatus';
import {companyLink, companyType, contactInfo} from '../../common/TableView/libs/cellRender';

const prepareForRenderSavedSearches = (data) => {
  const visibility = utils.prepareRecordVisibility(data);
  const usersAndTeamsInfo = utils.prepareUsersAndTeamsInfo(data);

  return {
    ...data,
    visibility,
    id: data.savedSearchId,
    ...usersAndTeamsInfo
  };
};

function prepareForRenderPlacementsTableView(data) {
  try {
    return data.map((plc) => {
      return prepareForRenderPlacementTableView(plc);
    });
  } catch (err) {
    console.log('err:', err);
    return data;
  }
}

function prepareForRenderPlacementTableView(plc) {
  try  {
    const lookups = Lookups.lookups;
    plc.candidate = plc.candidate || {};
    plc.job = plc.job || {};
    plc.commission = plc.commission || {};
    plc.programInfo = plc.programInfo || {};
    plc.workLocation = plc.workLocation || {};
    plc.billingLocation = plc.billingLocation || {};
    plc.billingContact = plc.billingContact || {};
    plc.timeApproverContact = plc.timeApproverContact || {};
    plc.backupApproverContact = plc.backupApproverContact || {};
    plc.permanentRateInfo = plc.permanentRateInfo || {};
    plc.contractRateInfo = plc.contractRateInfo || {};

    const jobCompanyType = plc.job.companyType;
    plc.plcLink = <Link to={`/placement/${plc.placementId}`}>PLC{plc.placementId}</Link>;
    plc.candidateName = utils.shortName(plc.candidate.candidateName);
    plc.candidateSource = (keyBy(lookups.candidateSources, 'id')[plc.candidate.source] || {}).name || utils.camelize(plc.candidate.source);
    plc.candidateLink =  <Link to={`/candidate/${get(plc, 'candidate.id', '')}`}>{plc.candidateName}</Link>;
    plc.plcTitleLik = <Link to={`/placement/${plc.placementId}`}>{plc.jobTitle}</Link>;
    plc.jobLink = <Link to={`/job/${plc.job.id}`}>{plc.job.jobTitle}</Link>;
    plc.companyLink = companyLink({data: plc, companyOf: 'job'});
    plc.clientContact = contactInfo({data: plc, companyOf: 'job', companyType: 'CLIENT', returnLink: false});
    plc.clientContactLink = contactInfo({data: plc, companyOf: 'job', companyType: 'CLIENT', returnLink: true});
    plc.mspContact = contactInfo({data: plc, companyOf: 'job', companyType: 'MSP', returnLink: false});
    plc.mspContactLink = contactInfo({data: plc, companyOf: 'job', companyType: 'MSP', returnLink: true});
    plc.programClientLink = jobCompanyType !== 'PROGRAM' ? '' :
      <Link to={`/company/${(plc.programInfo.clientCompany || {}).companyId}`}>
        {(plc.programInfo.clientCompany || {}).name}
      </Link>;
    plc.programMspLink = jobCompanyType !== 'PROGRAM' ? '' :
      <Link to={`/company/${(plc.programInfo.mspCompany || {}).companyId}`}>
        {(plc.programInfo.mspCompany || {}).name}
      </Link>;

    plc.ownerName = utils.shortName(plc.owner);
    plc.primaryRecruiterName = utils.shortName(plc.primaryRecruiter);
    plc.coRecruiterName = utils.shortName(plc.commission.coRecruiter);
    plc.primarySalespersonName = utils.shortName(plc.commission.primarySalesperson);
    plc.accountCoordinatorName = utils.shortName(plc.commission.accountCoordinator);
    plc.accountManagerName = utils.shortName(plc.commission.accountManager);
    plc.backOfficeOwnerUserName = utils.shortName(plc.backOfficeOwnerUser);
    plc.contractorEngagementUserName = utils.shortName(plc.contractorEngagementUser);
    plc.sourcerName = utils.shortName(plc.commission.sourcer);
    
    plc.timeApproverName = utils.shortName(plc.timeApproverContact.contactName);
    plc.timeApproverLink = plc.timeApproverName ? <Link to={`/contact/${plc.timeApproverContact.contactId}`}>{plc.timeApproverName}</Link> : '';
    plc.backupApproverName = utils.shortName(plc.backupApproverContact.contactName);
    plc.backupApproverLink = plc.backupApproverName ? <Link to={`/contact/${plc.backupApproverContact.contactId}`}>{plc.backupApproverName}</Link> : '';
    plc.billingContactName = utils.shortName(plc.billingContact.contactName);
    plc.billingContactLink = plc.billingContactName ? <Link to={`/contact/${plc.billingContact.contactId}`}>{plc.billingContactName}</Link> : '';

    plc.workLoc = compact([plc.workLocation.city, plc.workLocation.state]).join(', ');
    plc.billLoc = compact([plc.billingLocation.city, plc.billingLocation.state]).join(', ');

    plc.scheduledStartDateFormat = utils.dateOnlyForRender(plc.scheduledStartDate, 'MM/DD/YYYY');
    plc.originalStartDateFormat = utils.dateOnlyForRender(plc.originalStartDate, 'MM/DD/YYYY');
    plc.effectiveDateFormat = utils.dateOnlyForRender(plc.effectiveDate, 'MM/DD/YYYY');
    plc.scheduledEndDateFormat = utils.dateOnlyForRender(plc.scheduledEndDate, 'MM/DD/YYYY');

    // rate Info
    const isPermanent = (plc.placementType === 'PERMANENT' || plc.placementType === 'EMBEDDED');
    const payModels = isPermanent ? lookups.placementPermanentPayModels : lookups.placementContractPayModels;
    const payModelValue = isPermanent ? plc.permanentRateInfo.payModel : plc.contractRateInfo.payModel;
    plc.payModel = (keyBy(payModels, 'id')[payModelValue] || {}).name;

    // permanent
    plc.permanentPayRate = isPermanent ? (plc.permanentRateInfo.payModelDetails || {}).payRate : '';
    plc.permanentPayRateForamt = plc.permanentPayRate ? utils.dollarForRender(plc.permanentPayRate, plc.currencyType, 'compact') : '';
    plc.permanentFee = isPermanent ? (plc.permanentRateInfo || {}).permanentFee : '';
    plc.permanentFeeFormat = plc.permanentFee ? plc.permanentFee + ' %' : '';
    plc.permanentFlatFee = isPermanent ? (plc.permanentRateInfo || {}).flatFee : '';
    plc.permanentFlatFeeForamt = plc.permanentFlatFee ? utils.dollarForRender(plc.permanentFlatFee, plc.currencyType, 'compact') : '';

    // contract
    plc.contractBillRate = !isPermanent ? (plc.contractRateInfo.payModelDetails || {}).billRate : '';
    plc.contractBillRateFormat = plc.contractBillRate ? utils.dollarForRender(plc.contractBillRate, plc.currencyType, 'compact') : '';
    plc.contractPayRate = !isPermanent ? (plc.contractRateInfo.payModelDetails || {}).payRate : '';
    plc.contractPayRateFormat = plc.contractPayRate ? utils.dollarForRender(plc.contractPayRate, plc.currencyType, 'compact') : '';
    plc.contractMarkup = !isPermanent ? (plc.contractRateInfo.payModelDetails ||  {}).markup : '';
    plc.contractMarkupFormat = plc.contractMarkup ? plc.contractMarkup + ' %' : '';

    plc.contractHolidayBillRate = !isPermanent ? (plc.contractRateInfo.payModelDetails || {}).holidayBillRate : '';
    plc.contractHolidayBillRateFormat = plc.contractHolidayBillRate ? utils.dollarForRender(plc.contractHolidayBillRate, plc.currencyType, 'compact') : '';
    plc.contractHolidayPayRate = !isPermanent ? (plc.contractRateInfo.payModelDetails || {}).holidayPayRate : '';
    plc.contractHolidayPayRateFormat = plc.contractHolidayPayRate ? utils.dollarForRender(plc.contractHolidayPayRate, plc.currencyType, 'compact') : '';
    plc.contractHolidayMarkup = !isPermanent ? (plc.contractRateInfo.payModelDetails ||  {}).holidayMarkup : '';
    plc.contractHolidayMarkupFormat = plc.contractHolidayMarkup ? plc.contractHolidayMarkup + ' %' : '';

    plc.contractOnCallBillRate = !isPermanent ? (plc.contractRateInfo.payModelDetails || {}).onCallBillRate : '';
    plc.contractOnCallBillRateFormat = plc.contractOnCallBillRate ? utils.dollarForRender(plc.contractOnCallBillRate, plc.currencyType, 'compact') : '';
    plc.contractOnCallPayRate = !isPermanent ? (plc.contractRateInfo.payModelDetails || {}).onCallPayRate : '';
    plc.contractOnCallPayRateFormat = plc.contractOnCallPayRate ? utils.dollarForRender(plc.contractOnCallPayRate, plc.currencyType, 'compact') : '';
    plc.contractOnCallMarkup = !isPermanent ? (plc.contractRateInfo.payModelDetails ||  {}).onCallMarkup : '';
    plc.contractOnCallMarkupFormat = plc.contractOnCallMarkup ? plc.contractOnCallMarkup + ' %' : '';

    plc.contractCallBackBillRate = !isPermanent ? (plc.contractRateInfo.payModelDetails || {}).callBackBillRate : '';
    plc.contractCallBackBillRateFormat = plc.contractCallBackBillRate ? utils.dollarForRender(plc.contractCallBackBillRate, plc.currencyType, 'compact') : '';
    plc.contractCallBackPayRate = !isPermanent ? (plc.contractRateInfo.payModelDetails || {}).callBackPayRate : '';
    plc.contractCallBackPayRateFormat = plc.contractCallBackPayRate ? utils.dollarForRender(plc.contractCallBackPayRate, plc.currencyType, 'compact') : '';
    plc.contractCallBackMarkup = !isPermanent ? (plc.contractRateInfo.payModelDetails ||  {}).callBackMarkup : '';
    plc.contractCallBackMarkupFormat = plc.contractCallBackMarkup ? plc.contractCallBackMarkup + ' %' : '';

    plc.contractOrientationBillRate = !isPermanent ? (plc.contractRateInfo.payModelDetails || {}).orientationBillRate : '';
    plc.contractOrientationBillRateFormat = plc.contractOrientationBillRate ? utils.dollarForRender(plc.contractOrientationBillRate, plc.currencyType, 'compact') : '';
    plc.contractOrientationPayRate = !isPermanent ? (plc.contractRateInfo.payModelDetails || {}).orientationPayRate : '';
    plc.contractOrientationPayRateFormat = plc.contractOrientationPayRate ? utils.dollarForRender(plc.contractOrientationPayRate, plc.currencyType, 'compact') : '';
    plc.contractOrientationMarkup = !isPermanent ? (plc.contractRateInfo.payModelDetails ||  {}).orientationMarkup : '';
    plc.contractOrientationMarkupFormat = plc.contractOrientationMarkup ? plc.contractOrientationMarkup + ' %' : '';

    plc.contractDoubleTimeBillRate = !isPermanent ? (plc.contractRateInfo.payModelDetails || {}).doubleTimeBillRate : '';
    plc.contractDoubleTimeBillRateFormat = plc.contractDoubleTimeBillRate ? utils.dollarForRender(plc.contractDoubleTimeBillRate, plc.currencyType, 'compact') : '';
    plc.contractDoubleTimePayRate = !isPermanent ? (plc.contractRateInfo.payModelDetails || {}).doubleTimePayRate : '';
    plc.contractDoubleTimePayRateFormat = plc.contractDoubleTimePayRate ? utils.dollarForRender(plc.contractDoubleTimePayRate, plc.currencyType, 'compact') : '';
    plc.contractDoubleTimeMarkup = !isPermanent ? (plc.contractRateInfo.payModelDetails ||  {}).doubleTimeMarkup : '';
    plc.contractDoubleTimeMarkupFormat = plc.contractDoubleTimeMarkup ? plc.contractDoubleTimeMarkup + ' %' : '';

    plc.billingScheduleFormat = (keyBy(lookups.placementBillingSchedules, 'id')[plc.contractRateInfo.billingSchedule] || {}).name || utils.camelize(plc.contractRateInfo.billingSchedule);

    // overtimeType
    const overtimeTypes = lookups.placementOvertimeTypes || [];
    const overTimeTypeValue = !isPermanent? plc.contractRateInfo.overtimeType : plc.permanentRateInfo.overtimeType;
    plc.overtimeTypeFormat = (keyBy(overtimeTypes, 'id')[overTimeTypeValue] || {}).name || 'Nonexempt';
    plc.contractOtBillRate = !isPermanent ? (plc.contractRateInfo.overtimeRate || {}).billRate : '';
    plc.contractOtBillRateFormat = plc.contractOtBillRate ? utils.dollarForRender(plc.contractOtBillRate, plc.currencyType, 'compact') : '';
    plc.contractOtPayRate = !isPermanent ? (plc.contractRateInfo.overtimeRate || {}).payRate : '';
    plc.contractOtPayRateFormat = plc.contractOtPayRate ? utils.dollarForRender(plc.contractOtPayRate, plc.currencyType, 'compact') : '';
    plc.contractOtMarkup = !isPermanent ? (plc.contractRateInfo.overtimeRate ||  {}).markup : '';
    plc.contractOtMarkupFormat = plc.contractOtMarkup ? plc.contractOtMarkup + ' %' : '';

    plc.statusFormat = (keyBy(lookups.placementStatuses, 'id')[plc.status] || {}).name || utils.camelize(plc.status);
    plc.placementTypeForamt = (keyBy(lookups.placementTypes, 'id')[plc.placementType] || {}).name || utils.camelize(plc.placementType);
    plc.patientCareTypeFormat = (keyBy(lookups.placementPatientCareTypes, 'id')[plc.patientCareType] || {}).name || utils.camelize(plc.patientCareType);
    plc.complianceStatusFormat = (keyBy(lookups.placementComplianceStatuses, 'id')[plc.complianceStatus] || {}).name || utils.camelize(plc.complianceStatus);

    plc.createdAtFormat = utils.dateForRender(plc.createdAt, 'MM/DD/YYYY - hh:mm A');
    plc.updatedAtFormat = utils.dateForRender(plc.updatedAt, 'MM/DD/YYYY - hh:mm A');
    plc.companyTypeFormat = companyType({data: plc, typeOf: 'job'});
    plc.workModeFormat = (keyBy(lookups.workMode, 'id')[plc.workMode] || {}).name || utils.camelize(plc.workMode);
    
    return plc;
  } catch (err) {
    console.log('err:', err);
    return plc;
  }
}

function prepareForRenderInTableJobs(data) {
  try {
    return data.map((job) => prepareForInTableRenderJob(job));
  } catch (err) {
    console.log('err:', err);
    return data;
  }
}

function prepareForInTableRenderJob(job) {
  try {
    job.tableViewJobId = settingConst.globalEntityAbbreviations.JOB + job.jobId;
    return job;
  } catch (err) {
    console.log('err:', err);
    return job;
  }
}

function prepareForRenderInTableSubmissions(data) {
  try {
    return data.map((submission) => prepareForRenderInTableSubmission(submission));
  } catch (err) {
    console.log('err:', err);
    return data;
  }
}

function prepareForRenderInTableSubmission(submission) {
  try {
    submission.tableViewSubmissionId = settingConst.globalEntityAbbreviations.SUBMISSION + submission.submissionId;
    return submission;
  } catch (err) {
    console.log('err:', err);
    return submission;
  }
}

function sortByMapToApi(entityType, sortField, sortOrder) {
  const sortByMap = searchConst.sortByMapLookup[entityType];
  const realSortFields = sortByMap[sortField].split(',');
  return realSortFields.map((f) => `${sortOrder}${f}`).join(',');
}

function searchResultsFieldsListMap(entityType, cols) {
  try {
    const defaultFieldsList = searchConst.defaultSearchResultsFieldsListLookup[entityType];

    // no implement this yet
    if (isEmpty(cols) && isEmpty(defaultFieldsList)) {
      return {};
    }

    // pull from user setting
    if (isEmpty(cols)) {
      const tableViewSettings = utils.getTableViewSettings();
      cols = tableViewSettings[entityType + 'Columns'];
    }

    // pull default one if no cols info is set
    if (isEmpty(cols)) {
      return defaultFieldsList ? {searchResultsFieldsList: defaultFieldsList} : {};
    }

    const searchResultsFieldsList = searchConst.searchResultsFieldsListLookup[entityType];
    const colIds = cols.map((col) => {
      return searchResultsFieldsList[col.colId];
    });

    return {searchResultsFieldsList: uniq(defaultFieldsList.concat(flattenDeep(compact(colIds))))};
  } catch (err) {
    console.log('searchResultsFieldsListMap:', err);
    return {};
  }
}

function sortByMapToRender(entityType, sortBy) {
  if(!sortBy) {
    return [''];
  }
  const sortByMap = searchConst.sortByMapLookup[entityType];
  const realSortFields = sortBy.split(',');
  const sortOrder  = realSortFields[0].charAt(0);
  const sortField = sortBy.replaceAll(sortOrder, '');
  const reverseSortByMap = invert(sortByMap);

  return [reverseSortByMap[sortField] || sortField, sortOrder];
}

async function sortBySynchronize(entityType, sortBy) {
  try {
    if (!searchConst.savedTableColumnsToDbLookup[entityType]) {
      return;
    }
    if (isEmpty(sortBy)) {
      return;
    }
    const colSettings = utils.getTableViewSettings()[entityType + 'Columns'];
    if (!colSettings) {
      return;
    }

    const sortInfo = sortByMapToRender(entityType, sortBy);
    const newColSettings = cloneDeep(colSettings).map((col) => {
      // no sort, default
      if (sortInfo.length === 1) {
        col.sort = null;
        col.sortIndex = null;
      }

      if (col.colId === sortInfo[0]) {
        col.sort = sortInfo[1] === '+' ? 'asc' : 'desc';
        col.sortIndex = 0;
      } else {
        col.sort = null;
        col.sortIndex = null;
      }

      return col;
    });

    if (!isEqual(newColSettings, colSettings)) {
      await apiUserStatus.updateSettings({[`${entityType}Columns`]: newColSettings});
    }
  } catch (err) {
    console.log('err in the sortBySynchronize');
  }
}

function lastSortBy(entityType) {
  try {
    if (!searchConst.savedTableColumnsToDbLookup[entityType]) {
      return;
    }

    const colSettings = utils.getTableViewSettings()[entityType + 'Columns'];
    if (!colSettings) {
      return;
    }

    const cols = colSettings.filter((col) => {
      return col.sort !== null;
    });

    if (cols.length) {
      const col = cols[0];
      if (col.sort) {
        const sortField = col.colId;
        const sortOrder = col.sort === 'asc' ? '+' : '-';
        return sortByMapToApi(entityType, sortField, sortOrder);
      }
    } else {
      return '';
    }
  } catch (err) {
    console.log('err:', err);
    return '';
  }
}

async function prepareForRenderSearch(data) {
  const additionalCriteria = cloneDeep(data) || [];

  let i =0;
  for (let additionalSearch of additionalCriteria) {
    switch (additionalSearch.name) {
    case 'bulkEmailOptOut':
      // take care of boolean fields
      additionalSearch.criteria.value = [additionalSearch.criteria.value[0] ? 'true' : 'false'];
      break;
    case 'parsedResumeAverageMonthsPerEmployer':
    case 'parsedResumeMonthsOfManagement':
    case 'parsedResumeMonthsOfExperience':
      // frontEnd to match detail overview as 'Year' unit
      // backend field and value all about 'Month'
      additionalSearch.criteria.value = additionalSearch.criteria.value.map(v => (Math.round( (v / 12) * 10 ) / 10));
      break;
    case 'parsedResumeFulltimeDirectHireIndex':
      // Temp vs Perm Experience - frontEnd
      // backend parsedResumeFulltimeDirectHireIndex
      additionalSearch = utils.hireIndex(additionalSearch);
      break;
    case 'ownerTeamIds':
    case 'primaryRecruiterTeamIds':
    case 'recruiterTeamIds':
    case 'relationshipOwnerTeamIds':
    case 'websiteRecruiterTeamIds':
    case 'salespersonTeamIds':
    case 'assignedToTeamIds':
      //combined ...TeamIds to the ...UserIds in the frontEnd
      const searchField = additionalSearch.name;
      let pos = -1, index = 0;
      for (let as of additionalCriteria) {
        if(as.name && as.name.indexOf(searchField.replace('Team', 'User')) !== -1 && as.criteria.option === additionalSearch.criteria.option) {
          pos = index;
          break;
        }
        index++;
      }
      if (pos !== -1) {
        additionalCriteria[pos].criteria.value = additionalCriteria[pos].criteria.value.concat(additionalSearch.criteria.value.map(v => v + '_' + searchField));
        additionalSearch = {};
      } else {
        additionalSearch.name = searchField.replace('Team', 'User');
        additionalSearch.criteria.value = additionalSearch.criteria.value.map(v => v + '_' + searchField);
      }
      break;
    case 'contactIds':
    case 'reportsToContactIds':
    case 'billingContactIds':
    case 'primaryContactIds':
      try {
        let selectedContacts = [];
        for (const id of additionalSearch.criteria.value) {
          if (ApiClient['contactsDetails'] && id) {
            const res = await ApiClient['contactsDetails']({id: id, saveActivity: false});
            const firstName = has(res, 'body.contactName.firstName') ? res.body.contactName.firstName : '';
            const lastName = has(res, 'body.contactName.lastName') ? res.body.contactName.lastName : '';
            selectedContacts.push({
              ...res.body,
              fullName: (firstName) + ' ' + lastName,
              entityType: 'CONTACT',
              entityId: get(res, 'body.contactId')
            });
          }
        }
        additionalSearch.criteria.value = selectedContacts;
      } catch (err) {
        console.log('err', err);
      }
      break;
    case 'companyIds':
    case 'parentCompanyIds':
    case 'childCompanyIds':
    case 'programCompanyIds':
      try {
        let selectedCompanies = [];
        for (const id of additionalSearch.criteria.value) {
          if (ApiClient['companiesDetails'] && id) {
            const res = await ApiClient['companiesDetails']({id: id, saveActivity: false});
            selectedCompanies.push({...res.body, entityType: 'COMPANY',  entityId: get(res, 'body.companyId')});
          }
        }
        additionalSearch.criteria.value = selectedCompanies;
      } catch (err) {
        console.log('err', err);
      }
      break;
    case 'candidateIds':
      try {
        let selectedCandidates = [];
        for (const id of additionalSearch.criteria.value) {
          if (ApiClient['candidatesDetails'] && id) {
            const res = await ApiClient['candidatesDetails']({id: id, saveActivity: false});
            const firstName = has(res, 'body.candidateName.firstName') ? res.body.candidateName.firstName : '';
            const lastName = has(res, 'body.candidateName.lastName') ? res.body.candidateName.lastName : '';
            selectedCandidates.push({
              ...res.body,
              fullName: firstName + ' ' + lastName,
              entityType: 'CANDIDATE',
              entityId: get(res, 'body.candidateId')
            });
          }
        }
        additionalSearch.criteria.value = selectedCandidates;
      } catch (err) {
        console.log('err', err);
      }
      break;
    case 'jobIds':
      try {
        let selectedJobs = [];
        for (const id of additionalSearch.criteria.value) {
          if (ApiClient['jobsDetails'] && id) {
            const res = await ApiClient['jobsDetails']({id: id, saveActivity: false});
            selectedJobs.push({...res.body, entityType: 'JOb',  entityId: get(res, 'body.jobId')});
          }
        }
        additionalSearch.criteria.value = selectedJobs;
      } catch (err) {
        console.log('err', err);
      }
      break;
    case 'listIds':
      try {
        let selectedLists = [];
        for (const id of additionalSearch.criteria.value) {
          if (ApiClient['listsDetails'] && id) {
            const res = await ApiClient['listsDetails']({id: id});
            selectedLists.push(res.body);
          }
        }
        additionalSearch.criteria.value = selectedLists;
      } catch (err) {
        console.log('err', err);
      }
      break;
    default:
    }
    additionalCriteria[i] = additionalSearch;
    i++;
  }

  return filter(additionalCriteria, function(additionalCriterion) {
    return !isEmpty(additionalCriterion.criteria);
  });
}

function prepareForApiSearch(data) {
  const cleanCriteria = cloneDeep(data);

  if (cleanCriteria.locations && cleanCriteria.locations.length) {
    cleanCriteria.locations = cleanCriteria.locations.map(l => {
      if (typeof l === 'string') {
        return l;
      }

      if (l && l.location) {
        return l.location;
      }

      return l;
    });
  }

  const additionalCriteria = (get(cleanCriteria, 'additionalCriteria') || []).filter(function(addCriterion){
    return !isEmpty(addCriterion.name);
  });

  if (additionalCriteria.length === 0) {
    delete cleanCriteria.additionalCriteria;
    return cleanCriteria;
  }

  additionalCriteria.forEach(function(additionalSearch) {
    if (!isEmpty(additionalSearch.name)) {
      utils.cleanSearchValues(additionalSearch);
    }

    switch (additionalSearch.name) {
    case 'companyIds':
    case 'parentCompanyIds':
    case 'childCompanyIds':
    case 'programCompanyIds':
    case 'contactIds':
    case 'reportsToContactIds':
    case 'billingContactIds':
    case 'primaryContactIds':
    case 'candidateIds':
    case 'jobIds':
    case 'listIds':
      const nameFieldLookups = {
        'companyIds': 'companyId',
        'parentCompanyIds': 'companyId',
        'childCompanyIds': 'companyId',
        'programCompanyIds': 'companyId',
        'contactIds': 'contactId',
        'reportsToContactIds': 'contactId',
        'billingContactIds': 'contactId',
        'primaryContactIds': 'contactId',
        'candidateIds': 'candidateId',
        'jobIds': 'jobId',
        'listIds': 'listId'
      };
      additionalSearch.criteria.value = utils.convertCollectionToArray(get(additionalSearch, 'criteria.value'), nameFieldLookups[additionalSearch.name]);
      break;
    case 'parsedResumeAverageMonthsPerEmployer':
    case 'parsedResumeMonthsOfManagement':
    case 'parsedResumeMonthsOfExperience':
      // frontEnd to match detail overview as 'Year' unit
      // backend field and value all about 'Month'
      additionalSearch.criteria.value = additionalSearch.criteria.value.map(v => v * 12);
      break;
    case 'parsedResumeFulltimeDirectHireIndex':
      // Temp vs Perm Experience - frontEnd
      // backend parsedResumeFulltimeDirectHireIndex
      additionalSearch = utils.hireIndex(additionalSearch);
      break;
    case 'ownerUserIds':
    case 'recruiterUserIds':
    case 'relationshipOwnerUserIds':
    case 'primaryRecruiterUserIds':
    case 'websiteRecruiterUserIds':
    case 'salespersonUserIds':
    case 'assignedToUserIds':
      // split to userIds and teamIds
      let userIds = [];
      let teamIds = [];
      additionalSearch.criteria.value.forEach(function(value) {
        const values = value.toString().split('_');

        if (values.length > 1) {
          teamIds.push(isNaN(+values[0]) ? values[0]: +values[0]);
        } else {
          userIds.push(isNaN(+values[0]) ? values[0]: +values[0]);
        }
      });

      if (!isEmpty(teamIds)) {
        additionalCriteria.push({name: additionalSearch.name.replace('User', 'Team'), criteria: {option: additionalSearch.criteria.option, value: teamIds}});
      }

      if (isEmpty(userIds) && ![searchConst.searchOptions.withValue.value, searchConst.searchOptions.withoutValue.value].includes(additionalSearch.criteria.option)) {
        additionalSearch.criteria = {};
      } else {
        additionalSearch.criteria.value = userIds;
      }
      break;
    case 'dateExpectedEndRange':
    case 'scheduledEndDate':
    case 'dateExpectedStartRange':
    case 'effectiveDate':
    case 'originalStartDate':
    case 'scheduledStartDate':
    case 'contractEffectiveDate':
    case 'contractExpirationDate':
    case 'contractTerminationDate':
      if ( [searchConst.before, searchConst.between, searchConst.after].includes(additionalSearch.criteria.option)) {
        additionalSearch.criteria.value.forEach(function(v, index) {
          additionalSearch.criteria.value[index] = utils.dateOnlyForApi(
            v,
            ['dateExpectedEndRange', 'scheduledEndDate', 'contractExpirationDate', 'contractTerminationDate'].includes(additionalSearch.name) ? false : true
          );
        });
      }
      break;
    case 'dateCreatedRange':
    case 'dateUpdatedRange':
    case 'dateClosedRange':
      const option = additionalSearch.criteria.option;
      const value = additionalSearch.criteria.value;
      if(option === searchConst.before) {
        additionalSearch.criteria.value = [utils.dateForApi(value[0], false)];
      } else if (option === searchConst.after) {
        additionalSearch.criteria.value = [utils.dateForApi(value[0], true)];
      } else if (option === searchConst.between) {
        additionalSearch.criteria.value = [utils.dateForApi(value[0], true), utils.dateForApi(value[1], false)];
      }
      break;
    case 'bulkEmailOptOut':
      // take care of boolean fields
      additionalSearch.criteria.value = [additionalSearch.criteria.value[0] === 'true' ? true : false];
      break;
    default:
    }
  });

  const cleanAdditionalCriteria = filter(additionalCriteria, function(additionalCriterion) {
    return !isEmpty(additionalCriterion.criteria);
  });

  cleanCriteria.additionalCriteria = cleanAdditionalCriteria;
  return cleanCriteria;
}

function prepareForUpdateEntitySearch(data) {
  const commonData = {
    createdAt: data.createdAt,
    updatedAt: (new Date()).toISOString(),
    badges: utils.convertIdsToOptionFormat(data.lookups.badges, data.badgeIds) || []
  };
  switch (data.entityType) {
  case 'candidate':
    return {
      ...commonData,
      ...{
        candidateId: data.candidateId,
        experienceLevel: data.experienceLevel,
        jobTitle: data.jobTitle,
        currentCompany: data.currentCompany,
        recruiter: data.recruiter,
        status: data.status,
        firstName: data.candidateName.firstName,
        lastName: data.candidateName.lastName,
        preferredName: data.candidateName.preferredName,
        location: data.additionalInfo.address
      }
    };
  case 'company':
    return {
      ...commonData,
      ...{
        companyId: data.companyId,
        name: data.name,
        location: data.address,
        jobTypes: data.jobTypes || [],
        status: utils.convertIdToName(data.lookups, 'companyStatues', data.status),
        owner: utils.convertIdToUser(data.lookups, 'users', data.ownerUserId),
        companyType: data.companyType
      }
    };
  case 'job':
    return {
      ...commonData,
      ...{
        companyId: data.companyId,
        company: data.company,
        jobId: data.jobId,
        jobTitle: data.jobTitle,
        jobType: data.jobType,
        numOpenPositions: data.numOpenPositions,
        rateInfo: data.rateInfo,
        openStatus: data.openStatus,
        primaryRecruiter: data.primaryRecruiter,
        location: data.location
      }
    };
  default:
    break;
  }
}

function prepareForAmplitudeEventProperties(parameters) {
  try {
    // convert value to lowercase readable
    Object.keys(parameters).forEach(function(k){
      if(!['additionalCriteria', 'sortBy'].includes(k)) {
        parameters[k] = utils.searchValueTransform( parameters[k]);
      }
    });

    // search - additionalCriteria array to flat json
    if (get(parameters, 'additionalCriteria')) {
      const entityType = (cloneDeep(parameters.entityType) || '').toLowerCase();
      const additionalSearchFields = additionalSearchFieldsConfig[entityType];
      const additionalCriteria = {};
      const optionLookup = [searchConst.searchOptions.includeAll,
        searchConst.searchOptions.includeAny,
        searchConst.searchOptions.exclude,
        searchConst.searchOptions.withValue,
        searchConst.searchOptions.withoutValue,
        searchConst.searchOptions.inTheNext,
        searchConst.searchOptions.inTheLast,
        searchConst.searchOptions.inTheLastCalendar,
        searchConst.searchOptions.inTheCurrentCalendar,
        searchConst.searchOptions.inTheNextCalendar
      ].concat(searchConst.searchOptions.date).concat(searchConst.searchOptions.number);
      const optionKeyBy = keyBy(optionLookup, 'id');

      parameters.additionalCriteria.forEach((criteria) => {
        if (criteria.name === 'activity') {
          const {dateRange=null, filterTypes=null, text=null} = criteria.criteria || {};
          additionalCriteria['activity'] = {};

          if (dateRange) {
            additionalCriteria['activity']['dateRange'] = (get(optionKeyBy, dateRange.option + '.name') || dateRange.option).toLowerCase();
            additionalCriteria['activity']['datevalue'] = utils.searchValueTransform(dateRange.value || [], false);
          }

          if (filterTypes) {
            additionalCriteria['activity']['filterBy'] = (get(keyBy([searchConst.searchOptions.includeAnyActivity, searchConst.searchOptions.includeAllActivity, searchConst.searchOptions.excludeActivity, searchConst.searchOptions.activityDefaultValue],
              'id'), filterTypes.option + '.name') || filterTypes.option).toLowerCase();
            additionalCriteria['activity']['types'] = utils.searchValueTransform(filterTypes.value || []);
          }

          if (text) {
            additionalCriteria['activity']['text'] = (get(keyBy([searchConst.searchOptions.includeAny, searchConst.searchOptions.includeAll, searchConst.searchOptions.exclude, searchConst.searchOptions.textDefaultValue],
              'id'), text.option + '.name') || text.option).toLowerCase();
            additionalCriteria['activity']['textValue'] = utils.searchValueTransform(text.value || []);
          }
        } else {
          let fieldName = criteria.name;
          let label =  additionalSearchFields && keyBy(additionalSearchFields, 'value')[fieldName];
          if (label) {
            fieldName = label.label ? label.label.split(/-| /).join('') : '';
            fieldName = fieldName.charAt(0).toLowerCase() + fieldName.substr(1);
          }
          additionalCriteria[fieldName] = {};
          const keyObj = optionKeyBy[get(criteria, 'criteria.option')];
          additionalCriteria[fieldName].filterBy =  ((keyObj ?  keyObj.name : get(criteria, 'criteria.option')) || '').toLowerCase();
          const dateOnlyFields = ['dateExpectedEndRange', 'scheduledEndDate', 'dateExpectedStartRange', 'effectiveDate', 'originalStartDate', 'scheduledStartDate'];
          additionalCriteria[fieldName].value = utils.searchValueTransform( get(criteria, 'criteria.value'), dateOnlyFields.includes(criteria.name) ? true : false);
        }
      });
      parameters = {...parameters, ...additionalCriteria};
      delete parameters.additionalCriteria;
    }
    delete parameters.entityType;
    delete parameters.scope;

    return {'searchParameters': {criteria: parameters}};
  } catch (err) {
    console.log(err);
    return {};
  }
}

function prepareCriteriaForSearch(criteria, api=false) {
  const newCriteria = !isEmpty(criteria) ? cloneDeep(criteria) : { maxDistance: searchConst.maxDistance,  additionalCriteria: [] };

  if (newCriteria.additionalCriteria) {
    newCriteria.additionalCriteria.forEach(function (additionalSearch) {
      if (!isEmpty(additionalSearch.name)) {
        utils.cleanSearchValues(additionalSearch, api);
      }
    });
  } else {
    newCriteria.additionalCriteria = [];
  }

  return newCriteria;
}

function hireIndex(additionalSearch) {
  const option = additionalSearch.criteria.option;
  switch (option) {
  case searchConst.below:
    additionalSearch.criteria.option = searchConst.above;
    additionalSearch.criteria.value = additionalSearch.criteria.value.map(v => 100 - v);
    break;
  case searchConst.above:
    additionalSearch.criteria.option = searchConst.below;
    additionalSearch.criteria.value = additionalSearch.criteria.value.map(v => 100 - v);
    break;
  default:
    const from = cloneDeep(additionalSearch.criteria.value[0]);
    additionalSearch.criteria.value[0] = 100 - additionalSearch.criteria.value[1];
    additionalSearch.criteria.value[1] = 100 - from;
  }

  return additionalSearch;
}

function prepareInactiveUsers(users) {
  let inactiveUsers = users.filter((user) => {
    return user.isActive === false;
  });

  inactiveUsers = inactiveUsers.map((user) => {
    return {
      id: user.userId,
      name:`${user.firstName} ${user.lastName} (Inactive)`,
      isInactive: true
    };
  });

  return inactiveUsers;
}

export {
  prepareForRenderSavedSearches,
  prepareForRenderSearch,
  prepareForRenderPlacementTableView,
  prepareForRenderPlacementsTableView,
  prepareForApiSearch,
  prepareForUpdateEntitySearch,
  prepareForAmplitudeEventProperties,
  prepareCriteriaForSearch,
  hireIndex,
  sortByMapToApi,
  sortByMapToRender,
  sortBySynchronize,
  searchResultsFieldsListMap,
  lastSortBy,
  prepareForRenderInTableJobs,
  prepareForRenderInTableSubmissions,
  prepareInactiveUsers
};
